.ant-form-item {
  font-family: $font-family-sans-serif !important ;
}
.ant-form-item-label {
  color: #484848;
  font-family: $font-family-sans-serif !important ;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ant-input {
  background: $white3 !important;
  font-family: $font-family-sans-serif !important ;
  height: 3rem;
}

.ant-picker {
  background: $white3 !important;
  font-family: $font-family-sans-serif !important ;
  height: 3rem;
  width: 100%;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid $risd-blue-100;
  background: $white3 !important;
  height: 48px;
  padding: 0 1rem;

  &:hover {
    border: 1px solid $color-primary !important;
  }
}

.ant-form-item .ant-form-item-explain-error {
  font-family: $font-family-sans-serif !important ;
}

.ant-btn {
  border-radius: 4px;
  background-color: $color-primary;
  padding: 0.875rem 0.438rem;
  color: $white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.313rem;
  text-transform: capitalize;
  height: auto;
  // border: none !important;
  letter-spacing: 0.024px;
  svg {
    path {
      fill: $white !important;
    }
  }

  &:hover,
  &:active {
    background-color: $color-primary !important;
    color: $white !important;
    outline: none !important;
    border: none !important;

    &:disabled {
      background-color: $gray-400 !important;
      color: $white !important;
    }
  }

  &:disabled {
    background: $gray-400;
    color: $white;

    svg {
      path {
        fill: $white !important;
      }
    }
  }
}

// .ant-checkbox-wrapper
// hover .ant-checkbox-inner, :where(.css-dev-only-do-not-override-1vr7spz).
// ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inne

.ant-checkbox .ant-checkbox-inner {
  ::after {
    border: 2px solid brown !important;
  }
}

.ant-checkbox-wrapper .ant-checkbox {
  background-color: #fff;
  border: 1.5px solid #a4a4a6 !important;
  outline: none !important;
  border: none !important;

  .ant-checkbox-inner {
    width: 1rem !important;
    height: 1rem !important;
    border: 1.5px solid #a4a4a6 !important;
    ::after {
      border: 2px solid green !important;
    }
  }
}

.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  border-color: $color-primary;
  background-color: $color-primary;
  width: 1rem !important;
  height: 1rem !important;
  &:after {
    border: 2px solid $white;
    border-top: 0;
    border-inline-start: 0;
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $color-primary;
  background-color: $color-primary;
}

.ant-form label {
  font-size: 0.875rem;
  font-weight: 400;
  color: $gray-800;
  line-height: 1.05rem;
}

.ant-select-single {
  height: 3rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 4px;
  border: 1px solid $risd-blue-100;
  background: $white3 !important;
  height: 3.1rem;

  &:hover {
    border: 1px solid $color-primary !important;
  }
}

.ant-select-selection-item {
  color: $green-dark-blue-500;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  padding: 0.875rem 1rem;
}

.ant-form-item .ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: unset;
  color: $federal-blue;
  font-size: 0.875rem;
}

.ant-table-wrapper .ant-table {
  font-size: 0.875rem;
  color: $gray-800;
  font-weight: 500 !important;
  font-family: $font-family-sans-serif;
}
:where(.css-dev-only-do-not-override-2q8sxy).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-2q8sxy).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  font-weight: 400 !important;
  color: $gray-700 !important;
  font-size: 0.75rem !important;
}
