.shine {
  background: #fafaff;
  background-image: linear-gradient(
    to right,
    #fafaff 0%,
    $color-primary 20%,
    #fafaff 40%,
    #fafaff 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  -webkit-animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;

  &.w1 {
    width: 20%;
  }
  &.w2 {
    width: 40%;
  }
  &.w3 {
    width: 60%;
  }
  &.w4 {
    width: 80%;
  }
  &.w5 {
    width: 100%;
  }
  &.h1 {
    height: 10px;
  }
  &.h2 {
    height: 20px;
  }
  &.h3 {
    height: 30px;
  }
  &.h4 {
    height: 40px;
  }
  &.h5 {
    height: 50px;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
