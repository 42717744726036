//
// Theme style
//

// Initialize
@import "init";

// Components
@import "components/custom/components.scss";
@import "components/components";

// Layout
@import "layout/layout";

@import url("../fonts/beVietnamPro.css");
@import "./pages/superagent/index";

html {
  scroll-behavior: smooth !important;
  scroll-padding-top: 100px !important; /* height of sticky header */
  scrollbar-color: $color-primary !important;
}

body {
  font-family: $font-family-sans-serif;
  background: $white;
  color: $white;
  line-height: $line-height-base;
  min-height: 100vh;
  font-weight: $font-weight-normal;
  scroll-behavior: smooth !important;
  scroll-padding-top: 100px !important; /* height of sticky header */
  scrollbar-color: $color-primary !important;
}
