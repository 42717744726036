.ant-modal {
  max-width: 100% !important;
  width: 98% !important;
  margin: auto;
  padding: 1.25rem !important;
  @media screen and (min-width: 719px) {
    width: 560px !important;
    padding: 20px !important;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  &.pin-overlay {
    padding: 0.5rem!important;
    @media screen and (min-width: 719px) {
      width: 420px !important;
      padding: 10px !important;
    }
  }
}

.ant-modal-content {
  width: inherit !important;
  padding: inherit !important;
  // margin: auto;
  // @media screen and (min-width: 719px) {
  //   width: 560px !important;
  // } p-0
}

