
.profilePage {
  display: grid;
  gap: 30px;
  color: #1F2937;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  .pageTitle {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #050649;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .mainArea {
    display: grid;
    grid-template-columns: 280px auto;
    gap: 40px;

    > :nth-child(1) {
      display: grid;
      gap: 30px;
      background-color: #FFFFFF;

      .profileBox {
        display: grid;
        padding: 30px;
        width: 280px;
        box-sizing: border-box;
        justify-content: center;
        border-bottom: 1px solid #E5E7EB;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          margin: auto;
          margin-bottom: 40px;
        }
      }

      .profileMenuTitle {
        color: #1F2937;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
      }

      .profileMenu {
        display: grid;

        > div {
          padding: 20px 30px;
          display: grid;
          grid-template-columns: 24px auto 24px;
          justify-items: left;
          align-items: center;
          border-bottom: 1px solid #E5E7EB;

          &:hover, &.active {
            background-color: #EDEFFD;
            color: #3B4EE4;
            cursor: pointer;
          }
        }
      }
    }

    .mainProfileArea {
      background-color: #FFFFFF;
      padding: 40px;
      height: calc(100dvh - 220px);
      overflow-y: auto;


      Form {
        display: grid;
        grid-template-columns: 48% 48%;
        justify-content: space-between;

        > .newRole {
          display: grid;
          grid-column: span 2;
          grid-template-columns: 48% 48%;
          justify-content: space-between;
        }

        .oneItemOnARow {
          grid-column: span 2;
          width: 100%;

          :has(>Button) {
            display: flex;
          }

          Button {
            width: 50%;
            margin: auto;
          }
        }
      }

      .security-screen {
        display: grid;
        padding-inline: 90px;
        gap: 50px;

        .securityTitle {
          color: #050649;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
        }

        .securityList {
          display: grid;

          > div {
            display: flex;
            padding: 20px 30px;
            align-items: flex-start;
            justify-content: space-between;
            border: 1px solid #E5E7EB;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.changePassword {
  display: grid;
  gap: 40px;
  padding: 20px;

  color: #050649;
  text-align: center;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  > :nth-child(1) {
    display: grid;
    gap: 20px;
    text-align: center;

    :has(> input) {
      gap: 20px;
    }

    input {
      display: grid;
      height: 45px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: none;
      background: #FAFAFF;
    }

    Button {
      width: 100%;
    }
  }
}
