.servicesBody {
  display: grid;
  gap: 3rem;

  .servicesMain {
    width: 100%;
    display: grid;
    gap: 1.875rem;
    @media screen and (min-width: 1026px) {
      width: 800px;
      margin: auto;
    }
  }

  .tabSwitcher {
    padding: 0.313rem 0.625rem;
    align-items: center;
    border-radius: 9px;
    background: $gray-100;
    color: $gray-400;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    display: grid;
    grid-template-columns: auto auto;

    @media screen and (min-width: 540px) {
      width: 420px;
      margin: auto;
    }

    > div {
      display: flex;
      padding: 0.4999rem 0.625rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      cursor: pointer;

      @media screen and (min-width: 540px) {
        width: 200px;
      }

      &.tabSwitcherActive {
        border-radius: 0.25rem;
        background: $white;
        color: $gray-800;
      }
    }
  }

  .input-style {
    width: 350px;

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
  .input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;

    @media screen and (max-width: 719px) {
      grid-template-columns: 1fr;
      gap: 0.635rem;
    }
  }

  :where(.css-dev-only-do-not-override-2q8sxy).ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: unset !important;
  }
}

// .otp-Pin input {
//   display: flex;
//   width: 60px;
//   height: 60px;
//   padding: 0.625rem;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   flex-shrink: 0;
//   color: $gray-800;
//   border-radius: 40px;
//   background: $white3;
// }

.otp-pin {
  display: flex;
  gap: 0.625rem;
}
.pin-separator {
  width: 0.5rem;
}

.otp-pin input {
  width: 60px !important;
  height: 60px;
  font-size: 0.813rem;
  padding: 0.625rem;
  margin: auto;
  border-radius: 60px;
  border: 1px solid var(--risd-blue-50, #edeffd);
  background: $white3;
  color: $gray-800;
  outline: none;
  transition: all 0.1s;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.065px;
}

.pin-overlay {
  // padding: 0.563rem 0.35rem !important;
  padding: 0.625rem !important;
  @media screen and (min-width: 719px) {
    padding: 10px 15px 20px 15px !important;
  }
}

.btn-white {
  background-color: $white !important;
  border-radius: 0.25rem !important;
  border: 0.063rem solid $color-primary !important;
  border-width: 0.063rem !important ;
  // width: 171px !important;
  color: $color-primary !important ;

  &.ant-btn:hover,
  &.ant-btn:active {
    background-color: $white !important;
    border-radius: 0.25rem !important;
    border: 0.063rem solid $color-primary !important;
    border-width: 0.063rem !important ;
    // width: 171px !important;
    color: $color-primary !important ;
  }
}
