.dashboards-cards {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;

  @media screen and (min-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (max-width: 1399px) and (min-width: 735px) {
    grid-template-columns: 1fr 1fr;
  }
  gap: 35px;
  margin: 30px 0;

  .card-acct-balance {
    width: 350px;
    height: 146px;
    border-radius: 0.625rem;
    background-color: $default-color;
    padding: 0.75rem 1.313rem;

    @media screen and (max-width: 735px) {
      width: 93.58%;
    }

    .balance-text {
      color: $indigo-200;
      margin-right: 0.625rem;
      font-size: 0.688rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.813rem;
      letter-spacing: -0.012rem;
    }
  }

  .acct-balance {
    color: $white;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
  }

  .cards {
    width: 350px;
    height: 146px;
    border-radius: 0.625rem;
    background-color: $white;
    padding: 1.875rem 1rem 1.25rem 1.313rem;

    @media screen and (max-width: 735px) {
      width: 93.58%;
    }
  }
}

.quick-actions {
  margin: 28px 0 30px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  gap: 68px;

  @media screen and (min-width: 1400px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 1399px) and (min-width: 735px) {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 2.5rem;
  }

  @media screen and (max-width: 426px) {
    grid-template-columns: repeat(2, 1fr);
    gap: unset !important;
    row-gap: 1rem !important;
  }

  .quick-item {
    width: 130px;
    height: 150px;
    border-radius: 0.625rem;
    background-color: $white;
    padding: 0.75rem 1.313rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 5px 3px -2px rgba(0, 0, 0, 0.02);
    cursor: pointer;
    color: $gray-500;

    &:hover {
      background-color: $sky-400;
      color: $white;

      .icon {
        fill: $white;
        stroke: $white;
      }

      .icon2 {
        fill: $sky-400;
        stroke: $white;
      } 
    }

    .quick-items-text {
      color: $gray-500;
      color: inherit;
      font-size: 0.875rem !important;
      margin-top: 1.438rem;
      &:hover {
        color: inherit;
      }
    }
  }
}
