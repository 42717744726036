//
// Footer
//

.footer {
  background-color: $white4;
  padding: 1.5rem 1rem;
  margin: 0 -1rem;
  @media screen and (min-width: 992px) {
    padding: 58px 85px 65.5px 85px;
  }

  @media screen and (min-width: 992px) {
    margin: 0rem -2.5rem;
  }

  .btn-agent {
    border: 1px solid $blue-600;
    cursor: pointer;
    background: $blue-600;
    border-radius: 4px;
    padding: 0.625rem;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875rem;
    text-align: center;
    height: 3.625rem;
    width: 15.563rem;

    color: $white2;
  }

  .footer-card {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    margin-bottom: 5.5rem;

    @media screen and (min-width: 992px) {
      margin-bottom: 187px;
      //    width: 778px;
    }
    @media screen and (max-width: 540px) {
      flex-direction: column;
      gap: 2rem;
    }

    .contact-card {
      display: flex;
      flex-direction: column;
      flex: 0.6;
      @media screen and (min-width: 992px) {
        flex: unset;
      }
      @media screen and (max-width: 540px) {
        flex: unset;
      }
    }
    .beAgent-card {
      display: flex;
      flex-direction: column;
      align-items: center !important;
      flex: 0.4;

      @media screen and (min-width: 992px) {
        flex: unset;
        width: 359px;
      }
      @media screen and (max-width: 540px) {
        flex: unset;
      }

      .beAgent-header {
        color: $federal-blue;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.8rem;
        letter-spacing: -1.92px;
      }
    }
    .contact-start {
      margin: 1.438rem 0 0.875rem 0;
      span {
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.875rem;
        letter-spacing: 0em;
        text-align: left;
        color: $black;
      }
    }

    .contact-info {
      display: flex;
      gap: 0.875rem;

      @media screen and (max-width: 540px) {
        flex-direction: column;
      }

      .contact-details {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .text {
          color: $gray-600;
          text-align: center;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.05rem;
        }
      }
    }
  }

  .termPoliCook {
    display: flex;
    gap: 3rem;
    justify-content: center;

    @media screen and (max-width: 540px) {
      display: grid;
      flex-direction: column;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      gap: 4.125rem;
      align-items: center;
      justify-content: center;
    }
  }

  .line {
    background-color: $line;
    height: 1px;
  }

  .copyright {
    display: flex;
    gap: 3rem;
    justify-content: space-between;

    @media screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
    }

    .social-icons {
      display: flex;
      gap: 2.25rem;
    }
  }
}

.landing-footer-container {
  @media screen and (max-width: 991px) {
      padding: 1.5rem 2rem !important;
  }
}