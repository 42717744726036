.superAgentDashboard {
  display: grid;
  // margin-right: 40px;
  gap: 40px;

  &* {
    box-sizing: border-box !important;
  }

  .completeRegistrationNotice {
    display: flex;
    width: 100%;
    padding: 24px;
    justify-content: space-between;
    gap: 16px;
    border-radius: 10px;
    border: 1px solid #D1D6FA;
    background: #EDEFFD;

    > :nth-child(1) {
      font-family: "Be Vietnam Pro", sans-serif;
      display: grid;

      > :first-child {
        color: #475367;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        strong {
          color: #101928;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .completeRegistrationLink {
        color: #6775EA;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .shilledLogo {
      margin-bottom: -24px;
    }
  }

  .titleAndCards {
    display: grid;
    gap: 16px;

    .titleAndActionButton {
      color: #101928;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      display: flex;
      justify-content: space-between;
    }

    .cardRow {
      display: flex;
      gap: 16px;
      justify-content: space-around;
      flex-wrap: wrap;

      .dashboardCard {
        display: grid;
        padding: 16px;
        width: 260px;
        height: 98px;
        border-radius: 12px;
        border: 1px solid #E4E7EC;
        background: #FFF;
        box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.02), 0px 3px 2px -2px rgba(0, 0, 0, 0.06);
        align-content: space-between;

        color: #1D2739;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;

        :first-child {
          display: flex;
          justify-content: space-between;
          color: #667185;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;

          .badge {
            display: flex;
            padding: 0 8px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 12px;
            background: #EDEFFD;
            color: #3B4EE4;
          }
        }
      }
    }
  }

  .agentListSection {
    display: grid;
    gap: 16px;

    .tableHeader {
      color: #101928;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      justify-content: space-between;

      > :last-child {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;

        Button {
          display: flex;
          height: 48px;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #3B4EE4;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }

        .filter {
          border-radius: 8px;
          border: 1px solid #3B4EE4 !important;
          background-color: rgba(255, 255, 255, 0);
          &:hover {
            background-color: rgba(255, 255, 255, 0) !important;
            color: #3B4EE4 !important;
          }
        }

        .exportToCsv {
          border-radius: 8px;
          background-color: #EDEFFD !important;
          &:hover {
            background-color: #EDEFFD !important;
            color: #3B4EE4 !important;
          }
        }
      }
    }


    .superAgentTable {
      max-width: 99vw;

      .ant-table {
        max-width: 100%;
        overflow-x: auto;
      }

      .actionButton {
        border-radius: 8px;
        border: 1px solid #E4E7EC;
        background: #FFF;
        display: flex;
        width: 32px;
        height: 32px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
      }

      .viewDetailsButton {
        display: flex;
        width: 100px;
        padding: 5.5px 0;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        border-radius: 10px;
        background: #EDEFFD;
        color: #3B4EE4;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }


}

.addAgentModal {
  font-family: "Be Vietnam Pro", sans-serif;
  display: grid;
  align-items: center;
  gap: 40px;
  border-radius: 8px;

  > :nth-child(1) {
    color: #061B01;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  > :nth-child(2) {
    display: grid;
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: left;

    > :nth-child(2) {
      display: flex;
      gap: 20px;
    }

    .addAgentCard {
      width: 240px;
      height: 156px;
      display: grid;
      border-radius: 5px;
      border: solid 1px #AAAAAA;
      padding: 20px;
      cursor: pointer;

      &:hover {
        background-color: #EDEFFD;
        border: solid 1px #3B4EE4;
      }
    }
  }
}


.addIndividualAccount {
  display: grid;
  padding: 40px;
  gap: 40px;
  font-family: "Be Vietnam Pro", sans-serif;
  color: #050649;
  border-radius: 8px;
  border: 1px solid #EDEFFD;
  background: #FFF;

  > :nth-child(1) {
    display: grid;
    grid-template-columns: 20px auto;
    justify-items: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  > :nth-child(2) {
    display: grid;
    gap: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    > :nth-child(1) {
      font-size: 20px;
    }

    > :nth-child(2) {
      display: grid;
      gap: 20px;
    }

  }

}

.addBulkAccount {
  display: grid;
  padding: 40px;
  gap: 40px;
  font-family: "Be Vietnam Pro", sans-serif;
  color: #050649;
  border-radius: 8px;
  border: 1px solid #EDEFFD;
  background: #FFF;
  width: 480px;
  justify-content: center;
  margin: auto;

  &:has(.titleAndActionButton) {
    width: 100%;
    margin: unset;
    justify-content: unset;
  }

  > :nth-child(1) {
    display: grid;
    grid-template-columns: 20px auto;
    justify-items: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .titleAndActionButton {
    color: #1F2937;
    line-height: 120%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    align-items: center;
  }

  .downloadSampleFile {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #3B4EE4;
    width: 314px;
    margin: auto;
  }

  .fileUpload {
    display: grid;
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    > :nth-child(2) {
      display: flex;
      align-items: center;
      gap: 20px;
      color: #666;

      .browserButton {
        display: flex;
        height: 48px;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #EDEFFD;
        color: #3B4EE4;
        font-size: 14px;
      }
    }

    > :nth-child(3) {
      color: #9CA3AF;
    }

    input {
      display: none;
    }
  }

  .actionButtons {
    display: flex;
    justify-content: space-around;
    gap: 20px;

    .cancel {
      display: flex;
      height: 48px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: #FFFFFF;
      border: 1px solid #3B4EE4;
      width: 190px;
    }

    .preview {
      display: flex;
      height: 48px;
      padding: 16px 24px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #3B4EE4;
      border: none;
      color: #FFFFFF;
      width: 190px;
    }
  }
}



.mestroForm {
  display: grid;
  grid-template-columns: 48% 48%;
  gap: 20px;
  justify-content: space-around;

  .singleColumn {
    display: grid;
    grid-template-columns: auto;
    grid-column: span 2;
    justify-content: center;
  }
}

.invitationSent {
  display: grid;
  gap: 40px;
  text-align: center;

  .invitationSentTitle {
    color: #050649;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .invitationSentMessage {
    color: #050649;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
}


.accountDetails {
  display: grid;
  gap: 30px;
  font-family: "Be Vietnam Pro", sans-serif;
  color: #050649;

  .headingTitle {
    color: #4B5563;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  .pageTitle {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #050649;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .actionButton {
    border-radius: 8px;
    border: 1px solid #E4E7EC;
    background: #FFF;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .activeStatus {
    background: #DCFCE7;
    color: #09C63E;
  }
  .suspended {
    background: #FEF9C3;
    color: #CA8A04;
  }
  .debitOnHold {
    background: #EDEFFD;
    color: #3B4EE4;
  }

  .tableStatus {
    display: flex;
    width: 100px;
    padding: 5.5px 0;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;

    &.activeStatus {
      background: #DCFCE7;
      color: #09C63E;
    }
    &.suspended {
      background: #FEF9C3;
      color: #CA8A04;
    }
    &.debitOnHold {
      background: #EDEFFD;
      color: #3B4EE4;
    }
    &.successful {
      background: #DCFCE7;
      color: #09C63E;
    }
    &.pending {
      background: #FEE2E2;
      color: #DC2626;
    }

  }

  > :nth-child(2) {
    display: grid;

    .boxDisplay {
      border: 0.5px solid #E5E7EB;
      background: #FFF;
      display: flex;

      .profileBox {
        display: grid;
        padding: 30px;
        width: 280px;
        box-sizing: border-box;
        justify-content: center;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
          margin: auto;
          margin-bottom: 40px;
        }
      }

      > :nth-child(2) {
        display: grid;

        > div {
          display: flex;
          width: 100%;

          > div {
            display: grid;
            color: #1F2937;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            padding: 30px;
            border: 0.5px solid #E5E7EB;
          }
        }
      }
    }


  }
}

.fundAccount {
  display: grid;
  gap: 60px;

  .fundAccountMain {
    width: 420px;
    display: grid;
    gap: 30px;
    margin: auto;


    .copyAccountDetails {
      color: #050649;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      background-color: #EDEFFD;
      padding: 20px 10px;
      border-radius: 8px;

      .accountInfoRow {
        display: flex;
        padding: 12px 8px;
        align-items: center;
        gap: 20px;

        > :nth-child(2) {
          display: grid;

          > :nth-child(2) {
            color: #050649;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.tabSwitcher {
  padding: 5px 10px;
  align-items: center;
  border-radius: 9px;
  background: #F3F4F6;
  color: #9CA3AF;
  font-family: "Be Vietnam Pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: grid;
  grid-template-columns: auto auto auto;

  > div {
    display: flex;
    //width: 200px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: 500px;
    cursor: pointer;

    &.tabSwitcherActive {
      border-radius: 4px;
      background: #FFF;
      color: #1F2937;
    }
  }
}
