@use 'assets/styles/components/variables' as *;

.personal-data-rights-container {
    background-color: $white3;


    display: grid;


    @media screen and (max-width: 991px) {
        padding: 3rem 2rem;
        gap: 1.5rem;
    }

    @media screen and (min-width: 992px) {
        padding: 80px 120px;
        gap: 80px;
    }

    .header-section {
        display: grid;
        gap: 20px;
        text-align: center;
        max-width: 1164px;

        .header-text {
            color: $foundation-Green-Dark-blue-200;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
        }
    }

    .main-content-container {

        margin: auto;
        background-color: $white;
        display: grid;



        @media screen and (min-width: 760px) {
            width: 720px;
            gap: 40px;
            padding: 40px;
        }

        @media screen and (max-width: 759px) {
            width: 100%;
            gap: 1.5rem;
            padding: 1.5rem
        }


        .rights-input-style {
            max-width: 310px;

            @media screen and (max-width: 540px) {
                max-width: 79vw;
            }
        }

        .rights-input-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.25rem;

            @media screen and (max-width: 719px) {
                grid-template-columns: 1fr;
                gap: 0.635rem;
            }
        }

        .note {
            margin: 16px 0 40px 0;
        }

    }

}