.payTv {
  display: grid;
  gap: 60px;

  .payTvMain {
    width: 420px;
    display: grid;
    gap: 30px;
    margin: auto;
    border-radius: 8px;
    background: #FFF;
    padding: 40px;

    svg {
      justify-self: center;
    }

  }
}
