@use 'assets/styles/components/variables' as *;

.about-container {
    width: 100vw;
    overflow: hidden;

    @media screen and (max-width: 991px) {
        .md-center {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .about-section {
        display: flex;
        align-items: center;
        background-color: $foundation-blue-200;
        padding: 2rem 1rem 5rem 1rem;
        flex-direction: column;
        width: 100%;
        gap: 2.5rem;


        @media screen and (min-width: 992px) {
            padding: 63px 94px 106.69px 109px;
            gap: 52px;
        }

        > :nth-child(1) {
            color: $white;
            text-align: center;
            font-style: normal;
            font-weight: 600;

        }

        > :nth-child(2) {
            display: grid;
            text-align: center;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 800px;
            height: 224px;
            padding: 28px 0px;
            flex-direction: column;
            gap: 28px;
            flex-shrink: 0;
            border-radius: 12px;
            border: 1px solid $foundation-blue-primary-75;
            background: linear-gradient(91deg, rgba(43, 121, 255, 0.40) 38.97%, rgba(230, 239, 255, 0.40) 111.78%);
            box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.10);

            @media screen and (max-width: 799px) {
                width: 90%;
                height: unset;
                padding: 28px 10px;
            }

            > :nth-child(3) {
                position: absolute;
                bottom: -60.961px;
                left: -74.961px;

                @media screen and (max-width: 799px) {
                    bottom: -60.961px;
                    left: -54.961px;
                }


            }

        }
    }

    .maestro-values-container {
        width: 100%;
        background-color: $white3;
        padding: 5rem 1rem;

        @media screen and (max-width: 990px) and (min-width: 650px) {
            padding: 5rem 3rem;
        }

        @media screen and (min-width: 991px) {
            padding: 87px 206px;
        }

        .values-content {
            display: grid;
            gap: 40px;
            justify-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: 10px;
            padding: 2rem 0;

            > :nth-child(1) {
                color: $foundation-blue-200;
                text-align: center;
                font-style: normal;
                font-weight: 600;

            }

            > :nth-child(2) {
                display: grid;
                gap: 24px;
                grid-template-columns: 1fr;
                width: 74%;

                @media screen and (min-width: 991px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    width: 100%;
                }

                @media screen and (max-width: 990px) and (min-width: 650px) {
                    grid-template-columns: 1fr 1fr 1fr;
                    width: 100%;
                }

                .values {
                    display: flex;
                    width: 100%;
                    padding: 20px;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    border-radius: 8px;
                    border: 1px solid $foundation-blue-primary-75;

                    @media screen and (min-width: 991px) {
                        width: 300px;
                    }

                    @media screen and (max-width: 990px) and (min-width: 650px) {
                        width: 250px;
                    }

                    :nth-child(1) {
                        display: grid;
                        justify-items: center;
                        gap: 8px;
                    }

                }
            }

            > :nth-child(3) {
                display: grid;
                gap: 24px;
                grid-template-columns: 1fr;
                width: 74%;

                @media screen and (min-width: 650px) {
                    grid-template-columns: 1fr 1fr;
                    width: 100%;
                }

                .values {
                    display: flex;
                    width: 100%;
                    padding: 20px;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;
                    border-radius: 8px;
                    border: 1px solid $foundation-blue-primary-75;

                    @media screen and (min-width: 991px) {
                        width: 462px;
                    }

                    @media screen and (max-width: 990px) and (min-width: 650px) {
                        width: 325px;
                    }

                    :nth-child(1) {
                        display: grid;
                        justify-items: center;
                        gap: 8px;
                    }

                }
            }
        }

    }

    .the-need-section {
        display: grid;
        gap: 52px;
        padding: 5rem 2rem;
        justify-content: center;
        background-color: $white;

        @media screen and (min-width: 992px) {
            padding: 103px 65px;
            gap: 52px;
        }

        > :nth-child(1) {
            display: grid;
            grid-template-columns: auto 1fr;
            width: 100%;
            align-items: center;
            gap: 12px;

            > :nth-child(1) {
                background-color: $foundation-blue-50;
                padding: 10Px 20px;
                color: $foundation-blue-200 !important;
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                border-radius: 0.25rem;
            }

            > :nth-child(2) {
                width: 100%;
                height: 2px;
                background-color: $foundation-blue-primary-75;

            }

        }

        > :nth-child(2) {
            p {
                color: $gray-800 !important;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                border-radius: 0.25rem;
                margin-left: 1rem;
            }

            display: grid;
            gap:92px;


            @media screen and (min-width: 1188px) {
                grid-template-columns: 1fr 1fr;
            }

            >:nth-child(1) {
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center
            }


            > :nth-child(2) {
                display: none;

                @media screen and (min-width: 1188px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }

        }

    }

    .mission-section {
        display: flex;
        align-items: center;
        background-color: $risd-blue-800;
        padding: 2rem 1rem 5rem 1rem;
        flex-direction: column;
        width: 100%;
        gap: 2.5rem;

        @media screen and (min-width: 992px) {
            padding: 113px 184px;
            gap: 52px;
        }

        > :nth-child(1) {
            display: flex;
            padding: 0px 28px;
            justify-content: center;
            align-items: center;
            gap: 28px;
            border-radius: 12px;
            box-shadow: 0px 0px 40px 1px rgba(255, 255, 255, 0.10);
            position: relative;

            @media screen and (max-width: 499px) {
                flex-direction: column;
                gap: unset;
                padding: 0px -28px;
            }

            .vision-mision {
                display: flex;
                padding: 30px 0px;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 16px;
                text-align: center !important;
                width: 100%;
            }

            .vl {
                @media screen and (max-width: 499px) {
                    height: 1px;
                    position: absolute;
                    width: 100%;
                    background-color: $foundation-blue-100;
                }

                @media screen and (min-width: 500px) {
                    flex-direction: column;
                    height: 100%;
                    position: absolute;
                    width: 0.5px;
                    ;
                    background-color: $foundation-blue-100;
                }

            }
        }


        > :nth-child(2) {
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            width: 100%;
            align-items: center;
            gap: 12px;

            .line {
                width: 100%;
                height: 2px;
                background-color: $foundation-blue-100;
            }

            > :nth-child(2) {
                background-color: $foundation-blue-primary-500;
                padding: 10Px 20px;
                color: $white !important;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
                border-radius: 0.25rem;
            }



        }

        > :nth-child(3) {
            @media screen and (max-width: 991px) {
                padding: 0px 1rem;

            }

            display: flex;
            align-items: center;
            max-width: 562px;
            

            p {
                color: $white !important;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                border-radius: 0.25rem;
                margin-left: 1rem;
                text-align: center
            }

        }
    }

}