.registration-container {
  display: flex;
  flex-direction: column;
  //   width: 800px;
  min-height: 100vh;
  //   justify-content: center;
  align-items: center;
  background-color: $white3;

  .logo {
    width: 190.825px;
    height: 30px;
    margin-top: 2.5rem;

    @media screen and (min-width: 719px) {
      margin-top: 100px;
    }
  }

  .reg-form {
    display: flex;
    // align-items: center;
    flex-direction: column;
    width: 96%;
    padding: 1rem;
    gap: 2rem;
    border-radius: 0.5rem;
    border: 0.063rem solid $risd-blue-100;
    background: $white;
    margin-top: 2rem;

    @media screen and (min-width: 719px) {
      margin-top: 90px;
    }

    @media screen and (min-width: 801px) {
      width: 800px;
      padding: 40px;
      gap: 40px;
    }

    .create-account {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.813rem;
      letter-spacing: 0em;
      text-align: center;
      color: $federal-blue;
    }

    .form-title {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.813rem;
      letter-spacing: 0em;
      text-align: center;
      color: $federal-blue;
    }

    .personal-info {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-align: center;
      color: $federal-blue;
    }

    .input-style {
      width: 350px;

      @media screen and (max-width: 540px) {
        width: 100%;
      }
    }
    .input-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.25rem;

      @media screen and (max-width: 719px) {
        grid-template-columns: 1fr;
        gap: 0.635rem;
      }
    }

    .bvn {
      margin-top: -1.25rem;
      margin-bottom: 1.25rem;

      @media screen and (max-width: 719px) {
        margin-top: -0.635rem;
        margin-bottom: 0.635rem;
      }
    }
  }

  .otp-form {
    display: flex;
    // align-items: center;
    flex-direction: column;
    //background-color: #fff;
    width: 92%;
    padding: 1rem;
    gap: 2rem;
    border-radius: 0.5rem;
    border: 0.063rem;

    border-radius: 8px;
    border: 1px solid var(--risd-blue-50, #edeffd);
    background: var(--maestro-neutral-white, #fff);

    margin-top: 3.5rem;

    @media screen and (min-width: 719px) {
      margin-top: 103.5px;
    }

    @media screen and (min-width: 801px) {
      width: 540px;
      padding: 40px;
      gap: 40px;
    }

    .verify-email {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.813rem;
      letter-spacing: 0em;
      text-align: center;
      color: $federal-blue;
    }

    .otp-box {
      display: flex;
    }
    .box-separator {
      width: 0.5rem;
    }
    .otp-text {
      width: 383px;
    }

    .otp-box input {
      width: 45px !important;
      height: 45px;
      font-size: 1rem;
      padding: 10px;
      margin: 0 6px;
      border-radius: 6px;
      border: 1px solid var(--risd-blue-50, #edeffd);
      background: #fafaff;

      color: $text-black;
      outline: none;
      transition: all 0.1s;
      font-style: normal;
      font-weight: 400;
      line-height: 126.5%;
      /* or 20px */

      letter-spacing: 0.2px;
    }
  }
}

.header {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8rem;
  text-align: center;
  color: $federal-blue;
}
