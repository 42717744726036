//
// Custom Placeholder
//

.placeholder-white-opacity-75 {
  @include placeholder(rgba($white, 0.75));
}

.loader-class {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
