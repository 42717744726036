@use 'assets/styles/components/variables' as *;


.landing-layout-dark-container {
    overflow: hidden;
    width: 100vw;
}

.blue-bar {
    background-color: $blue-600 !important;
    margin: 0rem -1em;
    height: 0.625rem;

    @media screen and (min-width: 992px) {
        margin: 0rem -2.5rem;
    }
}