.body-dashboard {
  @media screen and (min-width: 992px) {
    //width: 100vw;
    overflow: hidden;
    margin-right: -2.5rem;
  }

  @media screen and (max-width: 991px) {
    .md-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .section-pos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.3rem 0 1rem 0;
    // column-gap: 40px;

    @media screen and (min-width: 992px) {
      margin: 100px -2.5rem 113px 0;
    }

    .intro-message-container {
      width: 100%;
      @media screen and (min-width: 992px) {
        width: 460px;
        margin: 0px 54px 0 40px;
      }
      .intro-message {
        font-size: 3.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 3.875rem;
        color: $gray-800;

        line-height: 114.286%; /* 114.286% */
letter-spacing: -2.24px;

      }
      .intro-message-desc {
        margin-top: 1.625rem;
      }
    }

    .userCardsContainer {
      width: 523.156px;
      height: 455.713px;
      justify-content: center;
      align-items: center;
    }
  }

  .posImageContainer {
    border-radius: 30px;
    width: 438px;
    background-color: $sky-400;
    height: 598px;
  }

  .btn-agent {
    border: 1px solid $blue-600;
    cursor: pointer;
    background: $blue-600;
    border-radius: 4px;
    padding: 0.625rem;
    font-family: $font-family-sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.875rem;
    text-align: center;
    height: 3.625rem;
    width: 15.563rem;

    color: $white2;
  }

  .service-section {
    background-color: #fefefd !important;
    padding: 2rem 0 2.5rem 0;
    @media screen and (min-width: 992px) {
      padding: 79px 43px 99px 60px;
    }
    .services-block {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 33px;
      row-gap: 27px;
      margin-top: 2.063rem;
      @media screen and (max-width: 540px) {
        grid-template-columns: 1fr;
      }
      @media screen and (min-width: 1197px) {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 69px;
      }
    }
  }

  .agent-section {
    display: flex;
    flex-direction: row;
    column-gap: 57px;
    align-items: center;
    padding: 2rem 0 2.5rem 0;
    @media screen and (min-width: 992px) {
      // padding: 125px 0 179.67px 89px;
      padding: 124px 145px 124px 84px;
    }

    .agent-lists {
      @media screen and (min-width: 992px) {
        width: 581px;
      }

      .agent-procedure {
        display: flex;
        // width: 490px;
        // height: 73px;

        @media screen and (min-width: 992px) {
          width: 490px;
          height: 73px;
        }
        padding: 0.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 1.313rem;
        border-radius: 1.25rem;
        background: $white;
        box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.1);
      }

      .num {
        display: flex;
        width: 2rem;
        height: 2rem;
        padding: 0.625rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;

        border-radius: 3.125rem;
        background: $blue-600;
        margin-right: 1.563rem;
      }
      .text {
        color: $gray-700;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;
      }
    }
  }

  .beAgent-section {
    background-color: #fefefd !important;
    padding: 2rem 0 2.5rem 0;
    @media screen and (min-width: 992px) {
      margin: 82px 149px 104px 88px;
    }

    .beAgent-header {
      color: $federal-blue;
      font-size: 3rem;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 3.6rem;
      letter-spacing: -1.92px;

      @media screen and (min-width: 992px) {
        width: 581px;
      }
    }

    .agent-requirement-group {
      margin-top: 52px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;

      @media screen and (max-width: 800px) {
        align-items: unset;
      }

      @media screen and (max-width: 540px) {
        display: block;
      }

      .group-item1 {
        @media screen and (min-width: 992px) {
          display: flex;
          gap: 25px;
          flex-direction: column;
          max-width: 323px;
        }
      }

      .group-item2 {
        @media screen and (min-width: 992px) {
          display: flex;
          gap: 25px;
          flex-direction: column;
          max-width: 295px;
        }
      }

      .requirement-item {
        display: flex;
        @media screen and (max-width: 540px) {
          display: inline-flex;
        }
        min-width: 266px;
        max-width: 323px;
        padding: 0.625rem 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;

        .text {
          color: $gray-700;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.875rem;
        }
      }
    }
  }
  .faq-section {
    background-color: $white;
    padding: 2rem 0 2.5rem 0;
    @media screen and (min-width: 992px) {
      padding: 61px 35px 93px 35px;
    }

    .faq-header {
      color: $federal-blue;
      font-size: 3rem;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      line-height: 3.6rem;
      letter-spacing: -1.92px;
    }
    .accordion-header {
      display: flex;
      gap: 3.75rem;
      padding: 0.625rem 1.25rem;
      background-color: $color-primary;
      .accordion-header-text {
        color: $white;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem;
        padding: 0.625rem;
      }
      .show {
        // color: $color-primary !important;
        // text-decoration: underline !important;
        // text-underline-offset: 0.625rem;
      }
    }

    .accordion-body {
      display: flex;
      flex-direction: column;
      margin-top: 3.125rem;
      padding: 1.25rem 0;
      gap: 1.25rem;
      background-color: $white3;
    }

    .faq-question {
      display: flex;
      justify-content: space-between;
      padding: 0 1.25rem;

      .faq-question-text {
        color: $color-primary;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.875rem;
      }
    }

    .faq-answer {
      padding: 0 1.25rem 0 3.75rem;

      @media screen and (max-width: 540px) {
        padding: 0 1.5rem 0.625rem 1.5rem;
      }

      .faq-answer-text {
        color: $default-color;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
      }
    }
  }
}
