//
// Layout Config
//

// scss-docs-start

// color-config
$white: #fff;
$black: #000;
$color-primary: #006cec;
$color-secondary: #a7a7a7;
$color-success: #1f8505;
$color-danger: #ed3a3a;
$color-warning: #ebbc2e;
$body-full-color: linear-gradient(180deg, #d2e7cd 0%, #fef5d7 100%);
$header-height: 93px; 

// Root font Sizes
$root-font-size: 16px; // Root font size for desktop mode
$root-font-size-lg: 14px; // Root font size for tablet mode
$root-font-size-md: 12px; // Root font size for mobile mode

// Footer
$footer-config: (
  // bg-color: '$color-primary',
) !default;
