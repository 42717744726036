@use 'assets/styles/components/variables' as *;


.privacy-container {

    display: grid;
   

    @media screen and (max-width: 991px) {
        padding: 3rem 2rem;
        gap: 1.5rem;
    }

    @media screen and (min-width: 992px) {
        padding: 80px 120px;
        gap: 40px;
    }

    .header {
        color: $foundation-Green-Dark-blue-200;
        text-align: center;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
    }

    p {
        color: $gray-500;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 161.9%;
    }

    .content {
        h1 {
            color: $gray-700;
            font-size: 1.25rem;
            margin-bottom: 0.75rem;
        }

        li {
            padding: 20px 0 20px 20px;
            align-items: flex-start;
            gap: 20px;
            color: $gray-700;
            font-size: 1.25rem;
            border-bottom: 1px solid $gray-200;
        }

        ::marker {
            font-size: 1.25rem;
        }

    }
}